import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import React from "react"
const Testimony = () => {
  const testimonies = [
    {
      id: 1,
      name: "Vitania Rebecca",
      company: "PT Kalbe Farma",
      testimony:
        "Layanannya cukup baik. Contact personnya mudah dijangkau. Mungkin yang perlu diperbaiki sistem pengiriman hasilnya distandarisasi agar customer lebih jelas. Selebihnya mantappppp!",
    },
    {
      id: 2,
      name: "Vitania Rebecca",
      company: "PT Kalbe Farma",
      testimony:
        "Layanannya cukup baik. Contact personnya mudah dijangkau. Mungkin yang perlu diperbaiki sistem pengiriman hasilnya distandarisasi agar customer lebih jelas. Selebihnya mantappppp!",
    },
    {
      id: 3,
      name: "Vitania Rebecca",
      company: "PT Kalbe Farma",
      testimony:
        "Layanannya cukup baik. Contact personnya mudah dijangkau. Mungkin yang perlu diperbaiki sistem pengiriman hasilnya distandarisasi agar customer lebih jelas. Selebihnya mantappppp!",
    },
  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <section className="slider" style={{ backgroundColor: "#F8F8F8" }}>
      <div className="container">
        <h6 className="title-heading">What People Are Saying</h6>
        <Slider {...settings} className="slider-content btn-style-1">
          {testimonies.map((content) => (
            <div className="item">
              <div className="item-inner">
                <div className="item-inner-content">
                  <p className="content-testimony">{content.testimony}</p>
                  <h4 className="content-name">{content.name}</h4>
                  <h4 className="content-company">{content.company}</h4>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default Testimony

{
  /* <ContentSlider
        contents={testimonies}
        blogPostCls="post-style-1"
        imgEffect="rotate"
      /> */
}
